.book-aircraft-cards {
  @apply my-10 flex flex-col gap-4;
  .card-wrapper {
    @apply flex flex-col m-4 gap-4;
      @screen lg {
        @apply flex-row;
      }
    .figure-wrapper {
      @apply relative;
      @screen lg {
        @apply w-full;
      }
      @screen xl {
        @apply w-3/5;
      }
      figure {
        @apply relative rounded-lg overflow-hidden;
        img.image {
          @apply w-full object-cover object-center h-full;
        }
        figcaption {
          .high-demand {
            @apply absolute bg-pink-700/75 bottom-0 w-full px-4 py-1;
          }
          .flight-discounted {
            @apply absolute bg-primary text-black font-semibold italic bottom-0 w-full px-4 py-1;
          }
        }
      }
      .price-tag {
        @apply absolute top-10 right-0 bg-secondary rounded-l-full italic pl-4 py-1 flex items-center pr-2;
        span {
          @apply bg-black/75 rounded-full h-[0.65rem] w-[0.65rem] inline-block mr-2;
        }
      }
      .price-tag-original {
        @apply absolute top-5 left-5 bg-primary text-black italic rounded-md px-2 py-1 flex items-center line-through;
        span {
          @apply bg-black/75 rounded-md h-[0.65rem] w-[0.65rem] inline-block mr-2;
        }
      }
    }
    .info-wrapper {
      @apply flex flex-col gap-4;
      @screen lg {
        @apply w-2/3;
      }
      h3 {
        @apply uppercase font-semibold;
      }
      .description {
        @apply text-navbar text-xs;
      }
      .airplane-stats-wrapper {
        @apply gap-2;
        .airplane-stats {
          @apply text-xs gap-2;
          img {
            @apply h-4 w-4;
          }
        }
      }
      .book-wrapper {
        @apply flex items-center justify-between;
        .favourite {
          @apply border border-secondary inline-block p-1 rounded;
        }
        button {
          @apply text-sm;
        }
      }
    }
  }
}