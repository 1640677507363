form {
  @apply flex flex-col gap-4 my-6;
  & > div {
    @apply flex flex-row gap-4;
    & > * {
      @apply flex-1;
    }
  }
  label {
    @apply flex flex-col gap-4;
    .label-text {
      @apply uppercase text-navbar font-bold;
    }
  }

  select, input, textarea {
    @apply bg-navbar-dark border-2 border-navbar text-black py-2 px-3;
  }

  input[type="date"], input[type="time"] {
    &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }

  .icon-wrapper {
    @apply relative flex items-center flex-1;
    img {
      @apply absolute pl-2 h-8 w-8;
    }
    input {
      @apply pl-10 w-full;
    }
  }
}
.styled-input {
  @apply bg-navbar-dark border-2 border-navbar text-black py-2 px-3;
}
.no-flex { 
  flex: unset;
}