.card {
  border: 1px solid #292929;
  border-radius: 0px;
}

.card-header {
  background: #171717 0% 0% no-repeat padding-box;
  border: 1px solid #292929;
  opacity: 1;

  text-align: left;
  font: italic normal normal 15px PT Serif;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;

  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.card-header-title {
  margin-bottom: 0;
  font-size: 1.1rem;
}

.card-header-sub-title {
  color: #FEC100;
}

.card-body {
  background: #292929 0% 0% no-repeat padding-box;
  border: 1px solid #292929;
  opacity: 1;

  text-align: left;
  letter-spacing: 0px;
  color: #FEC100;
}

.card-body-title {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 16px;
  padding-top: 10px;
}

.modal-body {
  background: #292929 0% 0% no-repeat padding-box;
}