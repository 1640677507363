footer {
  &.main-footer {
    @apply bg-base-300 py-10 px-10 border-t-4 border-primary text-navbar;
    @screen md {
      @apply px-20;
    }

    .logo {
      @apply mb-2;
      img {
        height: 2em;
      }
    }
    .social {
      @apply flex gap-4 mt-5;
      a {
        @apply bg-neutral p-2 inline-block rounded-full;
      }
    }
    @apply flex gap-20 flex-wrap;
    & > div {
      @screen lg {
        @apply flex-1;
      }
    }
    span {
      @apply uppercase font-semibold text-white;
    }
    div.wrapper {
      @apply flex gap-10 mt-5;
    }
    ul {
      @apply flex flex-col gap-4 text-sm;
    }
  }
}