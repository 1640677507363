.carousel {
  .carousel-wrapper {
    @apply flex overflow-auto;
    & > * {
      flex: 0 0 auto;
    }
    .carousel-item {
      @apply relative;
      figure {
        @apply relative;
        img.image {
          @apply w-full object-cover object-center h-40;
        }
        figcaption {
          @apply absolute bottom-0 bg-black/50 w-full px-4 items-center py-1;
          p {
            @apply flex gap-2;
            .img.icon {
              @apply inline pr-2;
            }
            span.book {
              @apply flex-1 text-right text-primary;
            }
          }
        }
      }
      .price-tag {
        @apply absolute bottom-10 right-0 bg-secondary rounded-l-full italic pl-4 py-1 flex items-center pr-2;
        span {
          @apply bg-black/75 rounded-full h-[0.65rem] w-[0.65rem] inline-block mr-2;
        }
      }
      .price-tag-original {
        @apply absolute top-2 right-2 bg-primary text-black italic rounded-md px-2 py-1 flex items-center line-through;
        span {
          @apply bg-black/75 rounded-md h-[0.65rem] w-[0.65rem] inline-block mr-2;
        }
      }
    }
  }
  .nav-prev, .nav-next {
    @apply items-center flex px-2;
    img {
      @apply h-24 w-24;
    }
  }
  .nav-prev {
    img {
      @apply -rotate-90
    }
  }
  .nav-next {
    img {
      @apply rotate-90
    }
  }
}