@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'forms';
@import 'components/footer';
@import 'components/carousel';
@import 'components/cards';
@import 'components/airplaneCatCard';
@import 'components/mapbox-gl';

// .react-select-container > div > div{
//     background: #FFFFFF4D 0% 0% no-repeat padding-box;
//   border: 1px solid #FFFFFF;
//   color: #fff !important;
//   border-radius: 0;;
//   }
.react-select-container > div[class$="-control"] {
    height: 100%;
}

.h-100 { 
    height: 100%;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.page-container {
    flex: 1;
}

.border-red {
    --tw-border-opacity: 1;
    border-color: #ff642c;
}

.bg-red {
    background: #ff642c;
}

.button--primary {
    border: 1px solid #FEC300;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    padding: 10px 24px;
    font: Medium 16px/18px Montserrat;
    letter-spacing: 1.6px;
    color: #FEC100;
    text-transform: uppercase;
    background: none;
    vertical-align: middle;
}

.button--secondary {
    border: 1px solid #FEC300;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    padding: 10px 24px;
    font: Medium 16px/18px Montserrat;
    letter-spacing: 1.6px;
    color: #000000;
    text-transform: uppercase;
    background: #FEC100 0% 0% no-repeat padding-box;
    vertical-align: middle;
}

.button--danger {
    border: 1px solid #FF0148B2;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    padding: 10px 24px;
    font: Medium 16px/18px Montserrat;
    letter-spacing: 1.6px;
    color: #FF0148B2;
    text-transform: uppercase;
    background: none;
    vertical-align: middle;
}

.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

.navion-select {
    height: 45px;
}

.toastContainer {
    z-index: 1;
}

.z-index-high {
    z-index: 1000;
}

.text-center {
    text-align: center;
}



.loader-visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
}

.loader-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
}


$f-primary : 'Josefin Sans', sans-serif;
$f-secondary : 'Indie Flower', cursive;
$c-front : #FFFFFF;
$c-back : #1D1F20;
$c-back-light : #383838;
$c-hilight : #FFA500;
$s-footer-height : 3rem;

.svg-calLoader {
    width: 230px; height: 230px;
    transform-origin: 115px 115px;
    animation: 3.4s linear infinite loader-spin;
}

.cal-loader__plane { fill: $c-hilight; }
.cal-loader__path { stroke: $c-front; animation: 3.4s ease-in-out infinite loader-path; }

@keyframes loader-spin {
    to{
        transform: rotate(360deg);
    }
}
@keyframes loader-path {
    0%{
        stroke-dasharray:  0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
    50%{
        stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
    }
    100%{
        stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
}


.loader {
    position: fixed;
    top: 0; left: 0; z-index: 9999;
    width: 100vw; height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    // transition: opacity 0.2s;
}

.application-version {
    position   : fixed;
    bottom     : 0; left: 0; z-index: 9999;
    opacity    : 0.4;
    cursor     : default;
    user-select: none;
}

.vh100 {
    height: 100vh;
}


.min-100vh {
    min-height: 100vh;
}

.loader-inner {
    position: absolute;
    top: 35%;
    left: 48%;
    transform: translate(-50%);
}


.text-green {
    color: #C6FF2C;
}

.text-amber {
    color: #fbff2c;
}


.text-red {
    color: #ff642c;
}


.radio-label-text {
    top: 775px;
    left: 765px;
    width: 100%;
    height: 19px;

    text-align: left;
    font: normal normal medium 16px/18px Montserrat;
    letter-spacing: 1.6px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 0.7;
}

.icon-input input, .navion-select, input.icon-input {
    color: #FFFFFF;
}

.header-3 {
    top: 985px;
    left: 562px;
    width: 363px;
    height: 24px;

    text-align: left;
    font: normal normal medium 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}


.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}


.w-30 {
    width: 30%;
}


.w-35 {
    width: 35%;
}

.w-50 {
    width: 50%;
}


.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

.w-100 {
    width: 100%;
}

.coming-soon-title {
    /* Layout Properties */
    top: 2785px;
    left: 1041px;
    width: 75%;
    height: 75px;

    /* UI Properties */

    text-align: left;
    font: normal normal bold 30px/34px Montserrat;
    letter-spacing: 3px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}

.coming-soon-paragraph {
    top: 3682px;
    left: 1040px;
    width: 75%;

    text-align: left;
    font: normal normal normal 16px/22px Montserrat;
    letter-spacing: 0px;
    color: #DDDDDD;
    opacity: 1;
}

.coming-soon-container {
    margin-top: 63px;
}

.faded {
    opacity: 80%;
}